import { FiBox, FiHome, FiPieChart, FiSettings, FiUser } from "react-icons/fi";
import { BiBox } from "react-icons/bi";
import { HiOutlineDocumentReport } from "react-icons/hi";

export const DrawerURLs = () => {
  const userData = JSON.parse(localStorage.getItem("user_profile"));


  const URLValues = {
    Urls: [
      {
        icon: <FiHome />,
        name: "Dashboard",
        hasChildren: false,
        href: "/dashboard",
        isDashboard: true,
      },
      {
        icon: <FiUser />,
        name: "User Management",
        hasChildren: true,
        href: "",
        children: [
          {
            name: "Customers ",
            href: "/dashboard/user-management/customers",
          },
          {
            name: "Merchants",
            href: "/dashboard/user-management/merchants",
          },
        ],
      },
      // {
      //   icon: <BiBox />,
      //   name: "Funding",
      //   hasChildren: true,
      //   href: "",
      //   children: [
      //     {
      //       name: "Funding Customer Wallet",
      //       href: "/dashboard/funding/customer-wallet",
      //     },
      //   ],
      // },
      // {
      //   icon: <FiUserCheck />,
      //   name: "Debit Customer",
      //   hasChildren: false,
      //   href: "/dashboard/debit-customer-module",
      // },
      {
        icon: <FiBox />,
        name: "Products",
        hasChildren: false,
        href: "/dashboard/products",
      },
      {
        icon: <HiOutlineDocumentReport />,
        name: "Invoicing",
        hasChildren: true,
        href: "",
        children: [
          {
            name: "ERP Code",
            href: "/dashboard/invoicing/erp-code",
          },
          {
            name: "Upload Sales Data",
            href: "/dashboard/invoicing/upload-file",
          },
          {
            name: "View Validation Errors",
            href: "/dashboard/invoicing/view-validation-error",
          },
          {
            name: "Generate Invoices",
            href: "/dashboard/invoicing/generate-invoice",
          },
          {
            name: "Generated Invoices",
            href: "/dashboard/invoicing/generated-invoice",
          },
          {
            name: "Reports",
            href: "/dashboard/invoicing/invoice-report",
          },
          {
            name: "Debit View",
            href: "/dashboard/invoicing/invoice-debit",
          },
        ],
      },
      {
        icon: <FiPieChart />,
        name: "Report",
        hasChildren: true,
        href: "",
        children: [
          {
            name: "Agent Listing",
            href: "/dashboard/report/agent-listing",
          },
          {
            name: "Transaction Listing",
            href: "/dashboard/report/transaction-listing",
          },
          // {
          //   name: "Transaction Summary",
          //   href: "/dashboard/report/transaction-summary",
          // },
          {
            name: "Commission Wallet",
            href: "/dashboard/report/commission-wallet",
          },
          {
            name: "Main Wallet",
            href: "/dashboard/report/main-wallet",
          },
          {
            name: "Airtime Report",
            href: "/dashboard/report/airtime-report",
          },
          {
            name: "Data Report",
            href: "/dashboard/report/data-report",
          },
          {
            name: "Electricity Report",
            href: "/dashboard/report/electricity-report",
          },
          {
            name: "Cable TV Report",
            href: "/dashboard/report/cable-report",
          },
          // {
          //   name: "Cash-Out Report",
          //   href: "/dashboard/report/cashout-report",
          // },
          // {
          //   name: "Inward Transfers Report",
          //   href: "/dashboard/report/inward-transfers-report",
          // },
          // {
          //   name: "Outward Transfers Report",
          //   href: "/dashboard/report/outward-transfers-report",
          // },
        ],
      },
      {
        icon: <HiOutlineDocumentReport />,
        name: "Custom Reports",
        hasChildren: true,
        href: "",
        children: [
          {
            name: "WIPAY",
            href: "/dashboard/other-reports/wipay",
          },
          {
            name: "Ecobank Report",
            href: "/dashboard/other-reports/ecobank-report",
          },
        ],
      },
      {
        icon: <BiBox />,
        name: "Commission",
        hasChildren: false,
        href: "/dashboard/commissions",
      },
      {
        icon: <FiBox />,
        name: "Performance",
        hasChildren: false,
        href: "/dashboard/performance-management",
      },
      {
        icon: <FiBox />,
        name: "Card Network",
        hasChildren: false,
        href: "/dashboard/card-network",
      },
      {
        icon: <FiSettings />,
        name: "Settings",
        hasChildren: true,
        href: "",
        children: [
          {
            name: "Add Settlement Bank",
            href: "/dashboard/settings/change-bank-account",
          },
          {
            name: "Change PIN",
            href: "/dashboard/settings/change-pin",
          },
          {
            name: "Change Password",
            href: "/dashboard/settings/change-password",
          },
          {
            name: "Reset Transaction PIN",
            href: "/dashboard/settings/set-transaction-pin",
          },
        ],
      },
    ],
  };

  if (
    userData?.walletId !== process.env.REACT_APP_VIEW_WIPAY_REPORT &&
    userData?.walletId !== process.env.REACT_APP_VIEW_ECOBANK_REPORT
  ) {
    const values = filterURLWIPAYValues(URLValues);

    return values;
  } else {
    const values = FutherFilterConditionalReports(URLValues, userData);


    return values;
  }
};

function filterURLWIPAYValues(URLValues) {
  const filteredURLValues = URLValues;

  filteredURLValues.Urls = filteredURLValues.Urls.filter(
    (url) => url.name !== "Custom Reports"
  );

  return filteredURLValues;
}

function FutherFilterConditionalReports(URLValues, userData) {
  const filteredURLValues = { ...URLValues };

  filteredURLValues.Urls = filteredURLValues.Urls.map((parent) => {


    if (parent.name === "Custom Reports") {
      if (+userData?.walletId === process.env.REACT_APP_VIEW_ECOBANK_REPORT) {
        parent.children = parent.children.filter(
          (child) => child?.name === "Ecobank Report"
        );
      }

      if (+userData?.walletId === process.env.REACT_APP_VIEW_WIPAY_REPORT) {
        parent.children = parent.children.filter(
          (child) => child?.name === "WIPAY"
        );
      }

      return parent;
    }


    return parent;
  });

  return filteredURLValues;
}
